<template>
  <div class="d-flex">
    <v-text-field
      v-model="searchData.text"
      prepend-inner-icon="mdi-magnify"
      data-append-icon="mdi-tune-vertical"
      outlined
      dense
      clearable
      @click:prepend-inner="search()"
      @keydown.enter="search()"
      @click:clear="$emit('clear')"
      @input="input"
      placeholder="Поиск"
    >
      <template v-slot:prepend-inner v-if="searchData.archived">
        <v-chip
          small
          close
          @click:close="switchArchived()"
        >
          Архив
        </v-chip>
      </template>
      <template v-slot:append>
        <v-menu
          v-model="searchMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-tune-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-menu
                v-model="dateSearchMenu"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    <v-icon>mdi-calendar</v-icon>
                    Дата
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchData.date"
                  :first-day-of-week="1"
                  locale="ru-ru"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="clearDate()"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="search()"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-btn text @click="notViewed()" :class="{'chat-green' : searchData.notViewed}">Новые</v-btn>
              <v-btn text @click="switchArchived()" :class="{'chat-green' : searchData.archived}" v-if="!supportChats">Архивные</v-btn>
              <!--<v-btn text>Новые</v-btn>
              <v-btn text>Вы ответили</v-btn>
              <v-btn text>Без ответа</v-btn>-->
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: ['supportChats'],
  data() {
    return {
      searchData: {
        text: '',
        date: '',
        archived: 0,
        notViewed: false
      },
      searchMenu: false,
      dateSearchMenu: false
    }
  },
  methods: {

    input() {
      if (!this.searchData.text) this.searchData.text = '' // may be null
      this.$emit('search', this.searchData)
    },

    clearDate() {
      this.dateSearchMenu = false
      this.searchData.date = ''
      this.$emit('search', this.searchData)
    },

    notViewed() {
      this.searchData.notViewed = !this.searchData.notViewed
      this.search()
    },

    switchArchived() {
      this.searchData.archived = this.searchData.archived ? 0 : 1
      console.log(this.searchData.archived)
      this.search()
    },

    search() {
      this.searchMenu = false
      this.dateSearchMenu = false
      this.$emit('search', this.searchData)
    }
  }
}
</script>
