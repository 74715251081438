import moment from 'moment'
import { Http } from '../../../apis/Http'
import WS from '../../../apis/WS'

export default {

  async getChatDetails() {
    // console.log(this.$store.state.auth.userRole)
    if (!this.$store.state.auth.user) return // иначе бага при logout
    this.loading = true
    let params
    if (this.$store.state.auth.userRole === 'admin') {
      params = {
        chat_id: this.$route.params.chat_id,
        user_id: this.$route.query.user_id,
        to_support: this.toSupport ? 1 : 0
      }
    } else {
      params = {
        to_user_id: this.$route.query.user_id,
        request_id: this.$route.query.request_id,
        chat_id: this.$route.params.chat_id
      }
    }
    params.archived = this.isArchived
    const res = await Http.get('/chat/get-chat-details', { params })
      .catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })

    if (res?.data && res.data.chats.length) {
      this.chats = res.data.chats
      this.sortByNew()
      this.currentChat = this.chats.find((el) => el.id === res.data.chat_id)
      if (this.chats.length && !this.currentChat) this.currentChat = this.chats[0]
      if (this.currentChat) {
        this.currentChat.notViewed = 0
        if (this.$store.state.auth.userRole === 'admin') this.currentChat.last_message.support_viewed = 1
        else this.currentChat.last_message.viewed = 1
      }
    } else {
      this.chats = []
    }
  },

  sortByNew() {
    this.chats.sort((a, b) => {
      if (a.last_message.viewed === b.last_message.viewed) return 0
      if (!a.last_message.viewed && b.last_message.viewed) return -1
      return 1
    })
  },

  setLastMessageViewed(ids) {
    for (const chat of this.chats) {
      if (chat.last_message && ids.includes(chat.last_message.id)) {
        if (this.$store.state.auth.userRole === 'admin') chat.last_message.support_viewed = 1
        else chat.last_message.viewed = 1
      }
    }
  },

  async changeChat(chatId) {
    this.messagesVisible = true
    this.formBlocked = true
    this.currentChat = this.chats.find(el => el.id === chatId)
    this.setChatUrl()
    this.getMessages().then(() => {
      this.currentChat.notViewed = 0
      if (this.$store.state.auth.userRole === 'admin') this.currentChat.last_message.support_viewed = 1
      else this.currentChat.last_message.viewed = 1
    })
  },

  addMessage(message) {
    this.messages.push(message)
    this.scrollToLastMessage()
  },

  async getMessages() {
    if (!this?.currentChat?.id) {
      this.messages = []
      return
    }
    const res = await Http.get('/chat/get-messages', {
      params: {
        chat_id: this.currentChat.id
      }
    }).catch(e => {
      console.log(e)
    })

    if (!res?.data) return
    this.messages = res.data
    this.sendAllViewed()
    setTimeout(() => {
      this.scrollToLastMessage()
    }, 500)
  },

  async search(searchData) {
    const text = searchData.text.toLocaleLowerCase()
    this.clearSearch()
    if (searchData.archived !== this.isArchived) {
      this.isArchived = searchData.archived
      await this.getChatDetails()
    }
    const ids = []
    for (const chat of this.chats) {
      // if (chat.to_support) continue
      if (searchData.date) {
        if (moment(chat.last_message.created_at).format('YYYY-MM-DD') !== searchData.date) {
          ids.push(chat.id)
        }
      }
      if (searchData.notViewed && chat.last_message.viewed) ids.push(chat.id)
      const chatContent = document.querySelectorAll(`#chat-list-item-${chat.id} .chat-searchable`)
      let chatText = ''
      for (const el of chatContent) chatText += ' ' + el.textContent
      if (chatText.toLocaleLowerCase().indexOf(text) === -1) ids.push(chat.id)
    }
    for (const chat of this.chats) {
      if (ids.includes(chat.id)) chat.is_visible = 0
      else chat.is_visible = 1
    }
  },

  clearSearch() {
    for (const chat of this.chats) chat.is_visible = 1
  },

  formatDate(date) {
    return moment(date).format('LLL')
  },

  scrollToLastMessage() {
    const mesElements = document.getElementsByClassName('chat-message')
    if (mesElements.length) {
      this.$vuetify.goTo(mesElements[mesElements.length - 1], { container: '.chat-message-sheet', duration: 0 })
    }
  },

  listenOfArchive() {
    this.$socket.on('notification', async data => {
      const route = this.$store.state.auth.userRole === 'admin' ? 'Chats' : 'Chat'
      if (data.type !== 'request_to_archive' || this.$route.name !== route) return
      const index = this.chats.findIndex(el => el.request_id === data.request_id)
      if (index !== -1) {
        if (this.currentChat && this.chats[index].id === this.currentChat.id) {
          this.messages.push({
            user_id: 0,
            created_at: moment(),
            text: 'Запрос перемещен в архив',
            archive_message: true,
            user: {
              first_name: 'Сообщение'
            }
          })
        }
        this.chats.splice(index, 1)
      }
    })
  },

  backToChats() {
    this.messagesVisible = false
  },

  toInfo() {
    this.messagesVisible = false
    this.infoVisible = true
  },

  backToMessages() {
    this.messagesVisible = true
    this.infoVisible = false
  },

  setHeight() {
    this.windowHeight = window.innerHeight
  }
}
